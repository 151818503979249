<div [formGroup]="form" class="box-container">
  <ng-container *ngIf="negotiable; else noteTemplate">
    <div class="highlight mb-3 text-center">
      <img src="assets/img/mobile/lamp.svg" alt="highlight" />
      <p translate>This job allows negotiations</p>
    </div>
    <div class="text-center">
      <p class="mb-2" translate>Please state how much fee/buy-out you want to ask.</p>
      <p class="mb-2" translate>Important: from your mentioned fee, 15% commission will be deducted.</p>
      <p class="mb-2" translate>When interested the Caster will contact you.</p>
    </div>
  </ng-container>
  <ng-template #noteTemplate>
    <h4 translate>Write a note to the Caster</h4>
    <div class="note-rule">
      <div class="note-section">
        <h6>Do's</h6>
        <ul>
          <li translate>Only give info when relevant to job post</li>
          <li translate>Avoid giving info, just by giving info</li>
        </ul>
      </div>
      <div class="note-section">
        <h6>Don'ts</h6>
        <ul>
          <li translate>Forbidden to provide contact details</li>
          <li translate>Forbidden to negotiate about the fee</li>
          <li translate>Forbidden to ‘beg’ for the job’</li>
        </ul>
      </div>
    </div>
  </ng-template>
  <app-textarea
    class="bg-gray w-100 flex-fill border-0 rounded mb-1"
    formControlName="note"
    [placeholder]="getPlaceholder()"></app-textarea>

  <div class="text-center d-flex mt-3 justify-content-center">
    <app-button (click)="clickApply(true)" buttonStyle="white-outline" class="m-2">
      <div class="px-2" translate>Skip and apply</div>
    </app-button>
    <app-button [isDisabled]="!noteValue" (click)="clickApply(false)" buttonStyle="artyclick-purple" class="m-2">
      <div class="px-2" translate>Apply with note</div>
    </app-button>
  </div>
</div>
